<template>
    <keep-alive>
    <vlogin />
    </keep-alive>
</template>

<script>
// @ is an alias to /src
import vlogin from "@/components/vlogin";

export default {
  name: "v_Login",
  components: {
    vlogin
  }
};
</script>