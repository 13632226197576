<template>
  <div class="login-page">
    <div class="logo" @click="toindex()" title="返回首页">
      <img src="@/assets/logo.png" />
    </div>
    <div class="bg"></div>
    <div class="ikon">
      <img src="@/assets/ikon.png" />
      <div class="login-form">
        <div>
          <label class="lab">登陆名:</label>
          <input class="login_text" type="text" v-model="user" />
        </div>
        <div>
          <label class="lab">登陆密码:</label>
          <input class="login_text" type="password" v-model="pwd" />
        </div>
        <router-link tag="span" to="./reset_pwd">
          <span class="forget-psd">忘记密码</span>
        </router-link>
        <div class="submit">
          <span @click="login()" class="logbtn">登录</span>
        </div>
        <p class="to_reg">
          还没有开放平台充值账号？
          <router-link tag="span" to="/register">
            <span>去注册</span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  // inject:['app'],
  data() {
    return {
      user: "",
      pwd: "",
      isreload: true,
    };
  },
  activated() {
    if (window.sessionStorage.getItem("islogin") != null) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  },
  methods: {
    toindex() {
      this.$router.push("./homepage");
    },
    login() {
      var that = this;
      var results = "";
      if (this.radio == 1) {

        let params = { user_name: this.user, password: this.pwd };
        $.ajax({
          async: false,
          type: "post",
          url: this.$store.state.user.q_url + "/api/api/login",
          data: params,
          dataType: "json",
          success: function (res) {
            results = res;
            if (results.code != 4001) {
              that.$message({
                message: results.msg,
                type: "success",
                center: "true",
                offset: "60",
              });
              that.$store.commit("user/phone", results.data.phone);
              if (results.code == 2001) {
                that.$store.commit("user/p_id", results.data.p_id);
                that.$store.commit("user/m_id", results.data.m_id);
                if (results.data.virtual == 0) {
                  // 诚美商家未申请状态
                  that.$store.commit("user/u_type", 0);
                } else if (results.data.virtual == 2) {
                  //诚美商家申请了供应商未申请代理商
                  that.$store.commit("user/u_type", 2);
                } else if (results.data.virtual == 1) {
                  //诚美商家申请了代理商未申请供应商
                  that.$store.commit("user/u_type", 1);
                } else if (results.data.virtual == 3) {
                  //诚美商家既申请了供应商又申请了代理商
                  that.$store.commit("user/u_type", 3);
                }
              } else if (results.code == 2000) {
                that.$store.commit("user/m_id", results.data.m_id);
                if (results.data.status == 4) {
                  //虚拟平台未申请状态
                  that.$store.commit("user/u_type", 4);
                } else if (results.data.status == 5) {
                  //虚拟平台商家申请了供应商
                  that.$store.commit("user/u_type", 5);
                }
                that.$store.commit("user/vir_id", results.data.user_id);
              }
              var user = that.user;
              that.$store.commit("user/user_info", { user });
              that.$store.commit("login/login_info", "true");
              if (results.data.store_name != undefined) {
                that.$store.commit("user/storename", results.data.store_name);
              }
              that.$router.push("/homepage");
            } else {
              that.$message({
                message: results.msg,
                type: "error",
                center: "true",
              });
            }
          },
        });
      } else if (this.radio == 2) {
        let params = { account: this.user, password: this.pwd };
        $.ajax({
          async: false,
          type: "post",
          url: this.$store.state.user.q_url + "/api/api/sub_account_login",
          data: params,
          dataType: "json",
          success: function (results) {
            if (results.success == true) {
              that.$message({
                message: results.message,
                type: "success",
                center: "true",
                offset: "60",
              });
              that.$store.commit("user/p_id", results.data.fid);
              that.$store.commit("user/storename", results.data.account);
              that.$store.commit("user/phone", results.data.phone);
              that.$store.commit("user/goodstype", results.data.goods_type);
              that.$router.push("/sub_manage");
            } else {
              that.$message({
                message: results.message,
                type: "warning",
                center: "true",
                offset: "60",
              });
            }
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  height: 34px;
  width: 280px;
  margin: 0 auto;
  border: 1xp solid #000;
  padding-top: 12px;
}
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/Login_bg.png) no-repeat;
    opacity: 0.9;
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1000px;
  height: 500px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  overflow: hidden;
  > img {
    width: 570px;
    height: 100%;
  }
  .login-form {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 500px;
    width: 430px;
    background-color: #fff;
    padding: 70px;
    box-sizing: border-box;
    > div {
      border-radius: 20px;
      overflow: hidden;
    }
    > p {
      width: 210px;
      height: 20px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
    }
    .login_text {
      width: 240px;
      height: 40px;
      border: none;
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 18px;
      background: rgba(238, 238, 238, 1);
      border-radius: 20px;
      z-index: 10;
    }
    .forget-psd {
      width: 48px;
      height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 31px;
      display: block;
      position: relative;
      left: 190px;
      cursor: pointer;
    }
    .submit {
      height: 50px;
      margin: 10px;
      margin-top: 40px;
      cursor: pointer;
    }
    .submit .logbtn {
      width: 240px;
      height: 40px;
      background: rgba(55, 67, 214, 1);
      border-radius: 20px;
      display: block;
      color: #fff;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
    }
    .to_reg {
      width: 200px;
      height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 33px;
      margin-left: 30px;
      > span {
        color: #2860ea;
        cursor: pointer;
      }
    }
  }
}
</style>